import React from "react";

import Layout from "../components/layout";
import Seo from "../components/seo";
import ContactForm from "../components/forms/contact";
import Subscribe from "../components/forms/subscribe";

const contact = () => {
  return (
    <Layout>
      <Seo title="Contactez-nous" description="Nous contacter" />
      <div className="page">
        <ContactForm />
      </div>
      <Subscribe />
    </Layout>
  );
};

export default contact;
