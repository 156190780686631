import { useForm, ValidationError } from "@formspree/react";
import React from "react";
import { FaCheckCircle } from "react-icons/fa";
import styled from "styled-components";

function ContactForm({ article, author }) {
  const [state, handleSubmit] = useForm(process.env.GATSBY_FORMSPREE_GENERAL_ID);

  const Success = () => {
    for (const form of document.getElementsByTagName("form")) {
      form.reset();
    }
    return (
      <div
        style={{
          border: "1px solid #e5eff5",
          boxShadow: "0 2px 3px rgb(3 27 78 / 5%)",
          borderRadius: "var(--radius)",
          padding: "2rem 1.5rem",
          margin: "4rem 0",
          textAlign: "center",
          background: "#d4edda"
        }}
      >
        <p style={{ fontSize: "1.3rem", fontWeight: "bold", color: "#155724" }}>
          <FaCheckCircle /> Merci de nous avoir contacté <br />
        </p>
      </div>
    );
  };

  return (
    <section className="page contact-page">
      <article className="contact-form">
        {state.succeeded && <Success />}
        <h3>Nous conctacter</h3>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <input type="text" name="_gotcha" style={{ display: "none" }} />
            <input type="text" name="name" placeholder="nom" className="form-control" required />
            <ValidationError prefix="Name" field="name" errors={state.errors} />
            <input type="email" name="email" placeholder="email" className="form-control" required />
            <ValidationError prefix="Email" field="email" errors={state.errors} />
            <SelectStyled name="subject" id="subjects" className="form-control" required>
              <option value="" default>
                CHOISIR UN SUJET
              </option>
              <option value="priere">BESOIN DE PRIÈRE</option>
              <option value="temoignage">PARTAGER UN TÉMOIGNAGE</option>
              <option value="question biblique">QUESTION BIBLIQUE</option>
              <option value="informations">INFORMATIONS</option>
              <option value="partenariat">PROPOSITION DE PARTENARIAT</option>
              <option value="service">PROPOSITION DE SERVICE</option>
              <option value="accompagnement">PROGRAMME D'ACCOMPAGNENENT</option>
              <option value="don">DON</option>
              <option value="autre">AUTRE</option>
            </SelectStyled>
            <ValidationError prefix="Subject" field="subject" errors={state.errors} />
            <textarea name="message" rows="5" placeholder="message" className="form-control" required />
            <ValidationError prefix="Message" field="message" errors={state.errors} />
          </div>
          <button type="submit" className="submit-btn btn">
            Envoyer
          </button>
        </form>
      </article>
    </section>
  );
}

function Contact() {
  return <ContactForm />;
}
export default Contact;

const SelectStyled = styled.select`
  color: var(--clr-grey-1);
`;
